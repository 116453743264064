














import { Vue, Component } from 'vue-class-decorator'

@Component({
  metaInfo: {
    title: 'Извините, у Вас нет прав доступа для просмотра этой страницы'
  }
})
export default class Error403 extends Vue {
  goBack() {
    history.back()
  }
}
